import { type ClientLoaderFunctionArgs, redirect } from '@remix-run/react';

import { type DtoOpenProgramGroupGameResponse } from '@lp-lib/api-service-client/public';

import { LoadingSpinner } from '../components/LoadingSpinner';
import { APIServiceURL, publicFetchAPIService } from '../services/public';
import { tokenWithRedirect } from '../utils/router';

async function openGroupGame(groupId: string, token: string | null) {
  return await publicFetchAPIService<DtoOpenProgramGroupGameResponse>(
    new APIServiceURL(`/programs/groups/${groupId}/open-game`),
    { token: token ?? '', method: 'POST' }
  );
}

export const clientLoader = async (action: ClientLoaderFunctionArgs) => {
  const id = action.params.id;
  if (!id) {
    throw new Error('expected group id');
  }

  const resp = await tokenWithRedirect(
    (token) => openGroupGame(id, token),
    action.request.url,
    { preferRedirect: 'login' }
  );
  const url = new URL(resp.json.redirectTo);
  const searchParams = new URL(action.request.url).searchParams;
  searchParams.set('intro-id', id);
  url.search = searchParams.toString();
  return redirect(url.toString());
};

export function Component() {
  return <LoadingSpinner />;
}
